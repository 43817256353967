@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "yugothicMedium";
  font-weight: 500;
  src: url("fonts/yugothic-medium.ttc") format("truetype");
}
@font-face {
  font-family: "yugothicBold";
  font-weight: 900;
  src: url("fonts/yugothic-bold.ttf") format("truetype");
}
@layer utilities{
  .leading-custom{
    line-height: 18px;
  }
  .w-main{
    width:100%;
  }
  .text-sm{
    font-size:12px;
  }
  .font-family-medium{
    font-family: "yugothicMedium";
  }
  .w-textbox-sm{
    width: 140px;
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.datepicker-container .calendar-icon-block {
  position: relative;
}
.datepicker-container .calendar-icon-block .calendar-icon{
  top: 50%;
  transform: translateY(-50%);
  right: 0.75rem;
  height: 1.25rem;
  width: 1.25rem;
  padding: 0px;
  position: absolute;
}

.category-list .category-list-block .category-list-info-main{
  width: 100%;
  max-width: 124px;
}
.category-list .category-list-block .category-list-info-main .category-badge{
  min-width: 2.25rem;
  border-radius: 20px;
  height: 26px;
}
.category-list .category-list-block .category-list-info{
  height: 100%;
  min-height: 72px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
}
.category-list .category-list-block .category-list-info:hover .category-text, .category-list .category-list-block .category-list-info.active .category-text {
  font-weight: bold;
}
.category-list .category-list-block .category-list-info:hover .category-badge, .category-list .category-list-block .category-list-info.active .category-badge {
  font-weight: bold;
}
.category-list.arr-category-list .category-list-block .category-list-info-main{
  max-width: 124px;
}
.category-list.arr-category-list .category-list-block .category-list-info{
  height: 100%;
  min-height: 66px;
}
.\[\&\.active\]\:bg-sky-600.active{
  --tw-bg-opacity: 1;
  background-color: rgb(2 132 199 / var(--tw-bg-opacity));
}
.top-header .top-menu .active {
  --tw-bg-opacity: 1;
  background-color: #09A1EC;
  font-family: "yugothicBold";
  font-weight: bold;
}
.top-header .middle-menu .active {
  --tw-bg-opacity: 1;
  background-color: #09A1EC;
  font-family: "yugothicBold";
}
.top-header .bottom-menu .active {
  --tw-bg-opacity: 1;
  background-color: #09A1EC;
  font-family: "yugothicBold";
}
.top-header ul li{
  cursor: pointer;
}
.dropdown-ch button{
  width: 100%;
  height: 36px;
}
.datepicke-custom .react-datepicker__calendar-icon {
  position: absolute;
  left: 12px;
}
.datepicke-custom input.custom-react-datepicker{
  padding-left: 42px!important;
}
.search-dropdown svg.search-icon{
  position: absolute;
  left: 20px;
  z-index: 5;
  top: 10px;
}
.search-dropdown button{
  padding-left: 54px;
  border-radius: 30px;
  padding-right: 46px;
}
.custom-react-datepicker-container {
  .react-datepicker-wrapper {
    @apply w-full;
  }
  .react-datepicker__view-calendar-icon {
    .custom-react-datepicker {
      @apply px-3 py-2;
    }
    .react-datepicker__calendar-icon {
      top: 50%;
      transform: translateY(-50%);
      @apply right-3 h-5 w-5 p-0;
    }
  }
  .react-datepicker__month-container {
    .react-datepicker__day {
      height: 1.7rem;
      &:focus {
        @apply outline-sky-600;
      }
      &.react-datepicker__day--today {
        @apply rounded-[0.3rem] border border-sky-600 bg-white text-sky-600;
      }
      &.react-datepicker__day--selected {
        @apply bg-sky-600 text-white;
      }
      &.react-datepicker__day--keyboard-selected {
        @apply bg-white;
      }
      &:not(.react-datepicker__day--selected) {
        &.react-datepicker__day--outside-month {
          color: rgba(0, 0, 0, 0.25);
        }
      }
    }

    .react-datepicker__day--keyboard-selected:hover,
    .react-datepicker__month-text--keyboard-selected:hover,
    .react-datepicker__quarter-text--keyboard-selected:hover,
    .react-datepicker__year-text--keyboard-selected:hover {
      @apply bg-sky-600 text-white;
    }
  }
}
